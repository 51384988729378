body {
    box-sizing: border-box;
}

.SingleDatePicker {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
}

.SingleDatePickerInput {
    background-color: transparent;
}

.SingleDatePickerInput__withBorder {
    border: 0;
}

.DateInput {
    background: transparent;
}

.DateInput_input {
    background-color: transparent;
    padding: 18.5px 14px;
    font-size: 15px;
    line-height: 16px;
}

.DateInput_input__focused {
    border-bottom: 0;
}